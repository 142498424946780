import ModuleHome from "@/components/modules/ModuleHome";
import ModuleUsers from "@/components/modules/ModuleUsers";
import EntityUser from "@/components/entities/EntityUser";
import ModuleClients from "@/components/modules/ModuleClients.vue";
import EntityClient from "@/components/entities/EntityClient.vue";

export default [
    {path: '/', component: ModuleHome},
    {path: '/users', component: ModuleUsers},
    {path: '/users/:id', component: EntityUser},
    {path: '/clients', component: ModuleClients},
    {path: '/clients/:id', component: EntityClient}
]