<template>
    <div class="nevs-content" v-if="loaded">
        <div class="nevs-content-top-buttons">
            <NevsButton @click="saveClick()" class="success"><i class="fa-solid fa-floppy-disk"></i>
                {{ $LANG.Get('buttons.save') }}
            </NevsButton>&nbsp;
            <NevsButton @click="backClick" class="error"><i class="fa-solid fa-arrow-left"></i> {{
                    $LANG.Get('buttons.back')
                }}
            </NevsButton>
        </div>
        <NevsCard class="client-form">
            <NevsTextField readonly :error="clientValidation.name" :label="$LANG.Get('fields.name') + ':'"
                           v-model="client.name"></NevsTextField>
            <NevsTextField readonly :error="clientValidation.oib" :label="$LANG.Get('fields.oib') + ':'"
                           v-model="client.oib"></NevsTextField>
            <NevsTextField readonly :error="clientValidation.hash" :label="$LANG.Get('fields.hash') + ':'"
                           v-model="client.hash"></NevsTextField>
            <NevsTextField :error="clientValidation.api_token" :label="$LANG.Get('fields.apiToken') + ':'"
                           v-model="client.api_token"></NevsTextField>
            <NevsTextField :error="clientValidation.valid_until" :label="$LANG.Get('fields.validUntil') + ':'"
                           v-model="client.valid_until"></NevsTextField>
            <NevsSelect v-model="client.financial_status_id" :ajax="'select/financial-statuses'"
                        :label="$LANG.Get('fields.financialStatus')"></NevsSelect>
        </NevsCard>
        <NevsCard class="client-invoices">
            <table class="client-invoices-table">
                <tr>
                    <th>{{ $LANG.Get('fields.number') }}</th>
                    <th>{{ $LANG.Get('fields.date') }}</th>
                    <th>{{ $LANG.Get('fields.amount') }}</th>
                    <th>{{ $LANG.Get('fields.financialStatus') }}</th>
                    <th>{{ $LANG.Get('fields.pdf') }}</th>
                </tr>
                <tr v-for="(invoice, key) in client.invoices" :key="key">
                    <td>{{ invoice.number }}</td>
                    <td>{{ invoice.date }}</td>
                    <td style="text-align: right;">{{ invoice.amount }}</td>
                    <td style="text-align: right;">{{ invoice.financial_status }}</td>
                    <td style="text-align: center;"><a :href="invoice.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i></a></td>
                </tr>
            </table>
        </NevsCard>
    </div>
</template>

<script>
import Client from '@/models/Client';
import NevsTextField from "@/components/nevs/NevsTextField";
import NevsButton from "@/components/nevs/NevsButton";
import NevsCard from "@/components/nevs/NevsCard";
import NevsSelect from "@/components/nevs/NevsSelect.vue";

export default {
    name: "EntityClient",
    components: {NevsSelect, NevsTextField, NevsButton, NevsCard},
    data() {
        return {
            loaded: false,
            client: new Client({id: this.$route.params.id}),
            clientValidation: new Client({}, true),
            changePassword: false
        }
    },
    methods: {
        backClick() {
            if (window.history.length === 1) {
                window.close();
            } else {
                this.$router.back();
            }
        },
        saveClick() {
            let vm = this;
            this.$API.APICall('put', 'clients/' + this.client.id, this.client, (data, success) => {
                if (success) {
                    vm.$CROSS_TAB_BUS.TriggerEvent('reload-clients', {});
                    vm.$LOCAL_BUS.TriggerEvent('notification', {text: this.$LANG.Get('alerts.recordSaved')});
                    vm.backClick();
                }
            });
        }
    },
    mounted() {
        this.$store.commit('selectMenu', 'clients');
        this.$store.commit('selectSubMenu', null);

        let vm = this;
        this.$API.APICall('get', 'clients/' + this.client.id, {}, (data, success) => {
            if (success) {
                if (data.client !== null) {
                    vm.client.Fill(data.client);
                    vm.$store.commit('setBreadcrumbs', [
                        {
                            label: vm.$LANG.Get('modules.clients'),
                            link: '/clients'
                        },
                        {
                            label: vm.client.name,
                            link: null
                        }
                    ]);
                    vm.$nextTick(()=>{
                       vm.loaded = true;
                    });
                } else {
                    vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                }
            } else {
                vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
            }
            vm.$nextTick(() => {
                vm.loaded = true;
            });
        });
    }
}
</script>

<style scoped>
.client-form {
    max-width: 500px;
}

.client-invoices {
    margin-top: 10px;
    width: fit-content;
}

.client-invoices-table {
    border-spacing: 0;
    border-collapse: collapse;
}

.client-invoices-table td, .client-invoices-table th {
    border: 1px solid black;
    padding: 5px;
}
</style>