<template>
    <div class="nevs-card">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "NevsCard"
}
</script>

<style scoped>

</style>