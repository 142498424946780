<template>
    <div class="nevs-content">
        <div class="nevs-content-top-buttons">
            <NevsButton class="primary" @click="addClick">
                <i class="fa-solid fa-plus"></i>
                {{ $LANG.Get('buttons.add') }}
            </NevsButton>
            <NevsButton class="secondary" @click="filter('everyone')">
                <i class="fa-solid fa-filter"></i>
                {{ $LANG.Get('buttons.everyone') }}
            </NevsButton>
            <NevsButton class="secondary" @click="filter('activeInDebt')">
                <i class="fa-solid fa-filter"></i>
                {{ $LANG.Get('buttons.activeInDebt') }}
            </NevsButton>
            <NevsButton class="secondary" @click="filter('expiredInDebt')">
                <i class="fa-solid fa-filter"></i>
                {{ $LANG.Get('buttons.expiredInDebt') }}
            </NevsButton>
        </div>
        <NevsCard style="clear: both; margin-bottom: 10px;">
            <NevsTextField style="width: 300px; float: left;" v-model="tableData.filters.id" :label="$LANG.Get('fields.id')"></NevsTextField>
            <NevsTextField style="width: 300px; float: left;" v-model="tableData.filters.name" :label="$LANG.Get('fields.name')"></NevsTextField>
            <NevsTextField style="width: 300px; float: left;" v-model="tableData.filters.email" :label="$LANG.Get('fields.email')"></NevsTextField>
            <NevsTextField style="width: 300px; float: left;" v-model="tableData.filters.oib" :label="$LANG.Get('fields.oib')"></NevsTextField>
            <NevsSelect style="width: 300px; float: left;" :nullable="true" v-model="tableData.filters.financial_status_id" :ajax="'select/financial-statuses'"
                        :label="$LANG.Get('fields.financialStatus')"></NevsSelect>
            <NevsNumberField style="width: 300px; float: left;" v-model="tableData.filters.debt_from"
                           :label="$LANG.Get('fields.debtFrom')"></NevsNumberField>
            <NevsNumberField style="width: 300px; float: left;" v-model="tableData.filters.debt_to"
                           :label="$LANG.Get('fields.debtTo')"></NevsNumberField>
            <NevsDateField style="width: 300px; float: left;" v-model="tableData.filters.due_date_from"
                           :label="$LANG.Get('fields.dueDateFrom')"></NevsDateField>
            <NevsDateField style="width: 300px; float: left;" v-model="tableData.filters.due_date_to"
                           :label="$LANG.Get('fields.dueDateTo')"></NevsDateField>
            <NevsDateField style="width: 300px; float: left;" v-model="tableData.filters.valid_until_from"
                           :label="$LANG.Get('fields.validUntilFrom')"></NevsDateField>
            <NevsDateField style="width: 300px; float: left;" v-model="tableData.filters.valid_until_to"
                           :label="$LANG.Get('fields.validUntilTo')"></NevsDateField>
            <NevsDateField style="width: 300px; float: left;" v-model="tableData.filters.warning_date_from"
                           :label="$LANG.Get('fields.warningDateFrom')"></NevsDateField>
            <NevsDateField style="width: 300px; float: left;" v-model="tableData.filters.warning_date_to"
                           :label="$LANG.Get('fields.warningDateTo')"></NevsDateField>
            <NevsDateField style="width: 300px; float: left;" v-model="tableData.filters.block_date_from"
                           :label="$LANG.Get('fields.blockDateFrom')"></NevsDateField>
            <NevsDateField style="width: 300px; float: left;" v-model="tableData.filters.block_date_to"
                           :label="$LANG.Get('fields.blockDateTo')"></NevsDateField>
            <div style="clear: both;"></div>
        </NevsCard>
        <NevsCard style="clear: both;">
            <NevsTable
                :default-sort="tableData.defaultSort"
                :fields="tableData.fields"
                :height="tableData.height"
                :total-records="tableData.totalRecords"
                @reload="reloadTable">
                <tr v-for="(item, key) in tableData.records" :key="key" :style="{ 'color' : item.color }">
                    <td class="nevs-table-linked-cell" @click="openClick(item.id)">{{ item.id }}</td>
                    <td class="nevs-table-linked-cell" @click="openClick(item.id)">{{ item.name }}</td>
                    <td class="nevs-table-linked-cell" @click="openClick(item.id)">{{ item.email }}</td>
                    <td class="nevs-table-linked-cell" @click="openClick(item.id)">{{ item.oib }}</td>
                    <td class="nevs-table-linked-cell" @click="openClick(item.id)">{{
                            item.financial_status_display
                        }}
                    </td>
                    <td class="nevs-table-linked-cell" @click="openClick(item.id)">{{ item.unpaid_invoices }}</td>
                    <td class="nevs-table-linked-cell" @click="openClick(item.id)">{{ item.debt_display }}</td>
                    <td class="nevs-table-linked-cell" @click="openClick(item.id)">{{ item.due_date }}</td>
                    <td class="nevs-table-linked-cell" @click="openClick(item.id)">{{ item.valid_until }}</td>
                    <td class="nevs-table-linked-cell" @click="openClick(item.id)">{{ item.warning_date }}</td>
                    <td class="nevs-table-linked-cell" @click="openClick(item.id)">{{ item.block_date }}</td>
                    <td>
                        <span :title="$LANG.Get('tooltips.openInNewWindow')" class="nevs-table-button"
                              @click="openClick(item.id, true)"><i class="fa-solid fa-up-right-from-square"></i></span>
                    </td>
                </tr>
            </NevsTable>
        </NevsCard>
    </div>
</template>

<script>
import NevsCard from "@/components/nevs/NevsCard";
import NevsButton from "@/components/nevs/NevsButton";
import NevsTable from "@/components/nevs/NevsTable";
import NevsDateField from "@/components/nevs/NevsDateField.vue";
import NevsSelect from "@/components/nevs/NevsSelect.vue";
import NevsTextField from "@/components/nevs/NevsTextField.vue";
import NevsNumberField from "@/components/nevs/NevsNumberField.vue";
import moment from "moment/moment";

export default {
    name: "ModuleClients",
    components: {NevsNumberField, NevsDateField, NevsSelect, NevsTextField, NevsTable, NevsButton, NevsCard},
    data() {
        return {
            tableData: {
                fields: [
                    {
                        name: 'id',
                        label: this.$LANG.Get('fields.id')
                    },
                    {
                        name: 'name',
                        label: this.$LANG.Get('fields.name')
                    },
                    {
                        name: 'email',
                        label: this.$LANG.Get('fields.email')
                    },
                    {
                        name: 'oib',
                        label: this.$LANG.Get('fields.oib')
                    },
                    {
                        name: 'financial_status',
                        label: this.$LANG.Get('fields.financialStatus')
                    },
                    {
                        name: 'unpaid_invoices',
                        label: this.$LANG.Get('fields.unpaidInvoices')
                    },
                    {
                        name: 'debt',
                        label: this.$LANG.Get('fields.debt')
                    },
                    {
                        name: 'due_date',
                        label: this.$LANG.Get('fields.dueDate')
                    },
                    {
                        name: 'valid_until',
                        label: this.$LANG.Get('fields.validUntil')
                    },
                    {
                        name: 'warning_date',
                        label: this.$LANG.Get('fields.warningDate')
                    },
                    {
                        name: 'block_date',
                        label: this.$LANG.Get('fields.blockDate')
                    },
                    {
                        name: 'actions',
                        width: '50px',
                        label: ''
                    }
                ],
                filters: {
                    id: '',
                    name: '',
                    email: '',
                    oib: '',
                    financial_status_id: null,
                    debt_from: 0.01,
                    debt_to: 0,
                    valid_until_from: moment().format('YYYY-MM-DD'),
                    valid_until_to: null,
                    due_date_from: null,
                    due_date_to: null,
                    warning_date_from: null,
                    warning_date_to: null,
                    block_date_from: null,
                    block_date_to: null
                },
                records: [],
                lastRequest: null,
                totalRecords: 0,
                defaultSort: {
                    field: 'debt',
                    descending: true
                },
                filterTimer: null,
                height: '0px'
            }
        }
    },
    watch: {
        'tableData.filters': {
            handler() {
                if (this.filterTimer !== null) {
                    clearTimeout(this.filterTimer);
                }
                let vm = this;
                this.filterTimer = setTimeout(function () {
                    vm.reloadTable(vm.tableData.lastRequest);
                    vm.filterTimerOn = false;
                }, 500);
            },
            deep: true
        }
    },
    methods: {
        filter(type) {
            if (type == 'everyone') {
                this.tableData.filters = {
                    id: '',
                    name: '',
                    email: '',
                    oib: '',
                    financial_status_id: null,
                    debt_from: 0,
                    debt_to: 0,
                    valid_until_from: null,
                    valid_until_to: null,
                    due_date_from: null,
                    due_date_to: null,
                    warning_date_from: null,
                    warning_date_to: null,
                    block_date_from: null,
                    block_date_to: null
                }
            }
            if (type == 'activeInDebt') {
                this.tableData.filters = {
                    id: '',
                    name: '',
                    email: '',
                    oib: '',
                    financial_status_id: null,
                    debt_from: 0.01,
                    debt_to: 0,
                    valid_until_from: moment().format('YYYY-MM-DD'),
                    valid_until_to: null,
                    due_date_from: null,
                    due_date_to: null,
                    warning_date_from: null,
                    warning_date_to: null,
                    block_date_from: null,
                    block_date_to: null
                }
            }
            if (type === 'expiredInDebt') {
                this.tableData.filters = {
                    id: '',
                    name: '',
                    email: '',
                    oib: '',
                    financial_status_id: null,
                    debt_from: 0.01,
                    debt_to: 0,
                    valid_until_from: null,
                    valid_until_to: moment().add(-1, 'day').format('YYYY-MM-DD'),
                    due_date_from: null,
                    due_date_to: null,
                    warning_date_from: null,
                    warning_date_to: null,
                    block_date_from: null,
                    block_date_to: null
                }
            }
        },
        openClick(id, newWindow = false) {
            if (newWindow) {
                let routeData = this.$router.resolve('/clients/' + id);
                window.open(routeData.href);
            } else {
                this.$router.push('/clients/' + id);
            }
        },
        addClick() {
            this.$router.push('/clients/0');
        },
        reloadTable(request) {
            let vm = this;
            request.filters = this.tableData.filters;
            this.tableData.lastRequest = JSON.parse(JSON.stringify(request));
            this.$API.APICall('get', 'clients', request, (data, success) => {
                if (success) {
                    vm.tableData.records = data.records;
                    vm.tableData.totalRecords = data.total_records;
                } else {
                    vm.$LOCAL_BUS.TriggerEvent('popup', {text: vm.$LANG.Get('alerts.serverError'), type: 'alert'});
                }
            });
        },
        resolveWindowResize() {
            this.tableData.height = window.innerHeight - 205 + 'px';
        }
    },
    mounted() {
        this.$store.commit('selectMenu', 'clients');
        this.$store.commit('selectSubMenu', null);
        this.$store.commit('setBreadcrumbs', [
            {
                label: this.$LANG.Get('modules.clients'),
                link: null
            }
        ]);
        window.addEventListener('resize', this.resolveWindowResize);
        this.resolveWindowResize();
        let vm = this;
        this.$CROSS_TAB_BUS.ListenToEvent('reload-clients', () => {
            vm.reloadTable(vm.tableData.lastRequest);
        });
    }
}
</script>

<style scoped>

</style>